<template>
	<div class="settings-wrapper">
		<div class="outbound-inbound-routing-items q-pt-md">
			<q-input
				outlined
				v-model="anb_auth_pin"
				label="PIN"
				name="anb-auth-pin"
				hint="Audio Noticeboard authentication PIN"
				disable
			/>
			<details-button
				:tooltip_content="anb_auth_pin_tooltip_content"
				name="anb-pin"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'

export default {
	name: 'AudioNoticeboardAuthPin',
	components: {
		DetailsButton,
	},
	data() {
		return {
			anb_auth_pin_tooltip_content: `When updating an audio noticeboard via a phone call rather than using the web dashboard, you will be prompted to
			enter your authentication PIN.`,
		}
	},
	computed: {
		...mapGetters(['customer_group', 'customer_group_permissions']),
		anb_auth_pin() {
			return this.customer_group.customer_group.telephony_group_id
		},
	},
}
</script>

