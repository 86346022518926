<template>
	<div class="settings-wrapper" v-if="customer_group_permissions.audio_noticeboard && customer_noticeboard_nums_copy.length">
		<div
			class="outbound-inbound-routing-items q-pt-md"
		>
			<div
				v-for="(noticeboard_number, index) in customer_noticeboard_nums_copy"
				:key="noticeboard_number.id"
			>
				<AudioNoticeboard
					:index="index"
					:noticeboard_number="noticeboard_number"
					:loading="loading"
					:number_of_anbs="customer_noticeboard_nums_copy.length"
					@playTTS="playTTS"
				/>

			</div>
			<details-button
				:tooltip_content="bussiness_as_usual_anb_tooltip_content"
				name="default-anb-content"
			/>
		</div>
	</div>
</template>

<script>
import AudioNoticeboard from '@/components/datasets/edit/settings/AudioNoticeboard.vue'
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'
import { mapGetters } from 'vuex'

export default {
	name: 'AudioNoticeboardList',
	components: {
		AudioNoticeboard,
		DetailsButton,
	},
	props: {
		loading: Boolean
	},
	data() {
		return {
			bussiness_as_usual_anb_tooltip_content: `If you have no current incidents that require bespoke content on your Audio Noticeboard, you can use this
			feature to automatically revert your Audio Noticeboard to your standard content.`,
		}
	},
	computed: {
		...mapGetters([
			'customer_noticeboard_nums_copy',
			'customer_group_permissions',
		]),
	},
	methods: {
		playTTS(message) {
			this.$emit('playTTS', message)
		},
	},
}
</script>
