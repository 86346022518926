<template>
	<div class="settings-wrapper">
		<div class="outbound-inbound-routing-items q-pt-md">
			<q-input
				outlined
				v-model="default_amd_message_content"
				type="textarea"
				label="Answering Machine Detection (AMD)"
				name="amd-custom-audio"
				hint="Answering services custom audio"
			/>
			<preview-button
				:is_disabled="default_amd_message_content"
				:loading="loading"
				@playTTS=playTTS
			/>
			<details-button
				:tooltip_content="answering_services_custom_audio_tooltip_content"
				name="amd-custom-audio"
			/>
		</div>

	</div>
</template>

<script>
import { mapActions } from 'vuex'
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'
import PreviewButton from '@/components/datasets/utils/PreviewButton.vue'

export default {
	name: 'AnweringServicesCustomAudio',
	components: {
		DetailsButton,
		PreviewButton,
	},
	props: {
		loading: Boolean
	},
	data() {
		return {
			answering_services_custom_audio_tooltip_content: `When an outbound voice call detects it has reached an answering service (known as Answering
			Machine Detection, or AMD), custom audio will be played. If no custom audio exists, a copy of your outbound message (minus any response options)
			will be played.`,
		}
	},
	computed: {
		default_amd_message_content: {
			get() {
				return this.$store.getters.default_amd_message.message
			},
			set(val) {
				this.updateDefaultAMDMessageContent(val)
			},
		},
	},
	methods: {
		...mapActions(['updateDefaultAMDMessageContent']),
		playTTS() {
			this.$emit('playTTS', this.default_amd_message_content)
		},
	},
}
</script>

<style lang="scss" scoped>
.preview-btn {
	margin-top: 1rem;
}
</style>
