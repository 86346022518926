<template>
	<div class="settings-wrapper">
		<div class="outbound-inbound-routing-items q-pt-md">
			<q-select
				multiple
				use-input
				v-model="value"
				:options="options"
				:label="settings.label"
				:hint="settings.helper_text"
				:disable="settings.disabled"
				use-chips
				stack-label
				@filter=filterFn
			/>
			<div
				class="example-address-format"
				v-if="settings.address_format_example"
			>Example: {{ address_format_example }}</div>
			<details-button
				:tooltip_content="settings.tooltip_content"
				:name="settings.name"
			/>
		</div>
	</div>

</template>

<script>
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'
import { mapGetters } from 'vuex'

export default {
	name: 'MultipleOptionsSettings',
	components: {
		DetailsButton,
	},
	props: {
		settings: Object,
	},
	data() {
		return {
			options: []
		}
	},
	computed: {
		...mapGetters(['logged_user_data_fields']),
		value: {
			get() {
				return this.settings.model
			},
			set(val) {
				this.$store.dispatch(this.settings.model_action, val)
			},
		},
		address_format_example() {
			let string = ''
			for (let obj of this.value) {
				if (this.logged_user_data_fields[obj.value]) {
					string += this.logged_user_data_fields[obj.value] + ' '
				} else {
					if (obj.label == ', (comma)' || obj.label == '. (dot)') {
						string = string.slice(0, -1)
						string += obj.sign + ' '
					} else {
						string += obj.label + ' '
					}
				}
			}
			return string
		},
	},
	methods: {
		filterFn (val, update) {
			if (val === '') {
				update(() => {
					this.options = this.settings.options
				})
				return
			}

			update(() => {
				const needle = val.toLowerCase()
				this.options = this.settings.options.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.example-address-format {
	cursor: default;
	margin-top: 1rem;
}
</style>


