<template>
	<div class="settings-wrapper">
		<div class="outbound-inbound-routing-items q-pt-md">
			<q-select
				v-model="cli_number"
				use-input
				:options="options"
				label="CLI Number"
				name="cli-number"
				hint="Dedicated outbound number for voice calls"
				:disable="cli_numbers.length === 1"
				@filter=filterFn
			/>
			<details-button
				:tooltip_content="dedicated_outbound_number_tooltip_content"
				name="dedicated-outbound-number"
			/>
		</div>

	</div>
</template>

<script>
import { mapActions } from 'vuex'
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'

export default {
	name: 'DedicatedOutboundNumber',
	components: {
		DetailsButton,
	},
	data() {
		return {
			dedicated_outbound_number_tooltip_content: `Select the number to be used for outbound voice calls (otherwise known as a Caller Line Identifier
			or CLI).`,
			options: []
		}
	},
	computed: {
		cli_numbers() {
			return this.$store.getters.voice_numbers.map((obj) => {
				return {
					value: obj.number,
					label: obj.number,
				}
			})
		},
		cli_number: {
			get() {
				return this.$store.getters.cli_number
			},
			set(val) {
				this.updateCliNumber(val)
			},
		},
	},
	methods: {
		...mapActions(['updateCliNumber']),
			filterFn (val, update) {
				if (val === '') {
					update(() => {
						this.options = this.cli_numbers
					})
					return
				}

				update(() => {
					const needle = val.toLowerCase()
					this.options = this.cli_numbers.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
				})
			}
	},
}
</script>

