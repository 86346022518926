<template>
	<div class="settings-wrapper">
		<div class="outbound-inbound-routing-items q-pt-md">
			<q-input
				outlined
				v-model="value"
				:readonly="settings.read_only"
				:label="settings.label"
				:name="settings.name"
				:hint="settings.helper_text"
				:error=sms_error
				:error-message="settings.error_message"
				:class="{ 'error-space': sms_error }"
			/>
			<details-button
				:tooltip_content="settings.tooltip_content"
				:name="settings.name"
			/>
		</div>
	</div>
</template>

<script>
import DetailsButton from '@/components/datasets/edit/settings//DetailsButton.vue'

export default {
	name: 'CustomSenderName',
	components: {
		DetailsButton,
	},
	props: {
		settings: Object,
	},
	computed: {
		value: {
			get() {
				return this.settings.model
			},
			set(val) {
				this.$store.dispatch(this.settings.model_action, val)
			},
		},
		sms_error() {
			return this.settings.error_message ? true : false
		},
	},
}
</script>

<style scoped>
.error-space {
	margin-bottom: 10px;
}
</style>

