<template>
	<div v-if="noticeboard_number.noticeboard_created">
		<div :class="{'anb-wrapper': true, 'margin-bottom': add_margin}" >
			<div style="width: 100%;">
				<q-input
					outlined
					v-model="noticeboard_content"
					label="Default Content"
					:name="`anb-${index}`"
					:hint="`“Business as usual” Audio Noticeboard content: ( ${noticeboard_number.number} )`"
					type="textarea"
				/>

			</div>
			<div class="flex items-start">
				<preview-button
					:is_disabled="noticeboard_content"
					:loading="loading"
					@playTTS=playTTS
				/>
				<div class="preview-wrapper">
					<q-btn
						label="Restore Default"
						outline
						rounded
						color="primary"
						icon="settings_backup_restore"
						size="md"
						@click="restoreDefaultANBContent"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import PreviewButton from '@/components/datasets/utils/PreviewButton.vue'
import DatasetsAPI from '@/services/api/Datasets.js'

export default {
	name: 'AudioNoticeboard',
	components: {
		PreviewButton,
	},
	props: {
		index: Number,
		noticeboard_number: Object,
		loading: Boolean,
		number_of_anbs: Number
	},
	computed: {
		noticeboard_content: {
			get() {
				return this.$store.getters.default_noticeboards[
					this.noticeboard_number.number
				]
			},
			set(val) {
				this.updateNoticeboardContent({
					number: this.noticeboard_number.number,
					val: val,
				})
			},
		},
		add_margin() {
			return this.number_of_anbs > 1
		}
	},
	methods: {
		...mapActions(['updateNoticeboardContent']),
		playTTS() {
			this.$emit(
				'playTTS',
				this.$store.getters.default_noticeboards[this.noticeboard_number.number]
			)
		},
		async restoreDefaultANBContent() {
			let context = {
				"phone_number": this.noticeboard_number.number,
				"message": this.$store.getters.default_noticeboards[this.noticeboard_number.number]
			}
			const response = await DatasetsAPI.updateAudioNoticeboardByPhone(context)
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			} else {
				this.$q.notify(`Audio noticeboard for number: ${response.number} is restored to the default message.`)
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.anb-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.preview-wrapper {
	margin-top: 0.5rem;
	margin-left: 10px;
}

.margin-bottom {
	margin-bottom: 20px;
}

@media (max-width: $breakpoint-sm-max) {
	.preview-wrapper {
		margin-top: 1.5rem;
	}
}
</style>
