<template>
	<div>
		<MultipleOptionsSettings :settings="naming_conventions_for_reports_prop" />
		<PrimaryCountryCode />

		<div v-if="customer_group_permissions.voice_channel">
			<DedicatedOutboundNumber />
		</div>

		<div v-if="customer_group_permissions.audio_noticeboard &&
				noticeboard_number_options &&
				noticeboard_number_options.length">
			<MultipleOptionsSettings :settings="audio_noticeboard_numbers_prop" />
		</div>
		<div v-if="customer_group_permissions.sms_channel">
			<CustomSenderName :settings="sms_sender_name_prop" />
		</div>
		<div v-if="customer_group_permissions.voice_channel || customer_group_permissions.conference_bridge">
			<PrimaryTTSVoice :loading="loading_primary_tts" @playTTS="(data) => playTTS(data, 'loading_primary_tts')" />
		</div>
		<div v-if="customer_group_permissions.voice_channel">
			<AnweringServicesCustomAudio :loading="loading_anwering_services_custom_audio" @playTTS="(data) => playTTS(data, 'loading_anwering_services_custom_audio')" />
		</div>
		<div >
			<CallerDialBackMessage :loading="loading_caller_dial_back_message" @playTTS="(data) => playTTS(data, 'loading_caller_dial_back_message')" />
		</div>
		<div v-if="display_anb">
			<AudioNoticeboardList :loading="loading_audio_noticeboard_list" @playTTS='playTTS' />
		</div>
		<div v-if="customer_group_permissions.trigger_message_by_phone && customer_group.customer_group.telephony_group_id">
			<AudioNoticeboardAuthPin />
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import MessageFormAPI from '@/services/api/MessageForm.js'
import MultipleOptionsSettings from '@/components/datasets/edit/settings/MultipleOptionsSettings.vue'
import PrimaryCountryCode from '@/components/datasets/edit/settings/PrimaryCountryCode.vue'
import DedicatedOutboundNumber from '@/components/datasets/edit/settings/DedicatedOutboundNumber.vue'
import CustomSenderName from '@/components/datasets/edit/settings/CustomSenderName.vue'
import PrimaryTTSVoice from '@/components/datasets/edit/settings/PrimaryTTSVoice.vue'
import AudioNoticeboardAuthPin from '@/components/datasets/edit/settings/AudioNoticeboardAuthPin.vue'
import CallerDialBackMessage from '@/components/datasets/edit/settings/CallerDialBackMessage.vue'
import AnweringServicesCustomAudio from '@/components/datasets/edit/settings/AnweringServicesCustomAudio.vue'
import AudioNoticeboardList from '@/components/datasets/edit/settings/AudioNoticeboardList.vue'

export default {
	name: 'OutboundAndInboundRoutingContent',
	components: {
		MultipleOptionsSettings,
		PrimaryCountryCode,
		DedicatedOutboundNumber,
		CustomSenderName,
		PrimaryTTSVoice,
		AudioNoticeboardAuthPin,
		CallerDialBackMessage,
		AnweringServicesCustomAudio,
		AudioNoticeboardList,
	},
	computed: {
		...mapGetters([
			'customer_group',
			'default_tts_voice',
			'customer_group_permissions',
			'is_cli_number',
			'show_caller_dial_back_custom_audio',
			'noticeboard_numbers',
			'noticeboard_number_options',
		]),
		audio_noticeboard_numbers_prop() {
			return {
				label: 'ANB inbound number(s)',
				helper_text: 'Audio Noticeboard inbound number(s):',
				name: 'audio-noticeboard',
				tooltip_content: this.audio_noticeboard_inbound_number_tooltip_content,
				options: this.formated_noticeboard_number_options,
				multiselect_label: 'number',
				model: this.$store.getters.noticeboard_numbers,
				model_action: 'updateNoticeboardNumbers',
				disabled: this.$store.getters.noticeboard_number_options.length === 1,
			}
		},
		naming_conventions_for_reports_prop() {
			return {
				label: 'Naming convention',
				helper_text: 'Choose how to identify people in reports',
				name: 'naming-conventions',
				tooltip_content: this.naming_convention_tooltip_content,
				options: this.formated_naming_conventions_options,
				multiselect_label: 'label',
				model: this.$store.getters.naming_convention_list,
				model_action: 'updateNamingConventionList',
				address_format_example: true,
				disabled: false,
			}
		},
		sms_sender_name_prop() {
			return {
				setting_name: 'Custom SMS sender name',
				label: 'SMS',
				name: 'sms-sender',
				tooltip_content: this.custom_sms_sender_name_tooltip_content,
				model: this.$store.getters.sms_sender_name,
				helper_text: 'Custom SMS sender name',
				model_action: 'updateSMSSenderName',
				read_only: true
			}
		},
	},
	data() {
		return {
			naming_convention_tooltip_content: `Select which fields to use to identify your users in reports. If you select multiple fields you can optionally
			choose to insert a comma or full stop between your fields i.e. Surname, First Name. If you select multiple fields, a space will be automatically
			inserted between the fields, after any punctuation selected (if applicable).`,
			audio_noticeboard_inbound_number_tooltip_content: `Select the number your users should dial to hear your latest update.`,
			custom_sms_sender_name_tooltip_content: `A sender name of up to 11 characters may be used when sending SMS to supported countries. Sender names can use upper and
			lower case letters, numerical digits 0 through 9, & character and whitespace, but cannot consist only of numbers. Users will not be able to reply to messages using a Sender name unless
			you are using SMS links to capture responses. If you are sending a 2 way message and do not SMS links enabled, your message will be sent from a mobile phone number
			to ensure deliverability.`,
			formated_noticeboard_number_options: null,
			display_anb: true,
			loading_primary_tts: false,
			loading_anwering_services_custom_audio: false,
			loading_caller_dial_back_message: false,
			loading_audio_noticeboard_list: false

		}
	},
	methods: {
		async playTTS(text, arg1) {
			this[arg1]=true
			let name = this.default_tts_voice.name ? this.default_tts_voice.name : 'Amy'
			let message = {
				message: text,
				tts_voice: name,
			}
			const response = await MessageFormAPI.getTTSAudio(message)
			this[arg1]=false
			let audio = new Audio(response)
			audio.play()
		},
	},
	created() {
		this.formated_noticeboard_number_options =
			this.$store.getters.noticeboard_number_options.map((number) => {
				return {
					label: number.number,
					value: number.id,
				}
			})
		this.formated_naming_conventions_options =
			this.$store.getters.naming_convention_options.map((item) => {
				let obj = {}
				obj['label'] = item.label
				obj['value'] = item.id
				if (item.sign) {
					obj['sign'] = item.sign
				}
				return obj
			})
		this.formated_naming_conventions_options.sort((a, b) => a.label.localeCompare(b.label))

		if (!this.noticeboard_numbers) {
			this.display_anb = false
		}
	},
}
</script>
