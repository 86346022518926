<template>
	<div class="settings-wrapper">
		<div class="outbound-inbound-routing-items q-pt-md">
			<q-input
				outlined
				v-model="dial_back_message"
				type="textarea"
				label="Caller dial back"
				name="caller-dial-back"
				hint="Caller dial back custom audio"
				:disable="!edit_dial_back"
			/>

			<preview-button
				:is_disabled="dial_back_message"
				:loading="loading"
				@playTTS=playTTS
			/>
			<details-button
				:tooltip_content="caller_dialback_tooltip_content"
				name="amd-custom-audio"
			/>
		</div>

	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'
import PreviewButton from '@/components/datasets/utils/PreviewButton.vue'

export default {
	name: 'CallerDialBackMessage',
	components: {
		DetailsButton,
		PreviewButton,
	},
	props: {
		loading: Boolean
	},
	data() {
		return {
			caller_dialback_tooltip_content: `If you do not have Audio Noticeboard enabled in your service, you must create custom audio to be played to any
			of your users who dial back on your dedicated outbound voice call number.`,
		}
	},
	computed: {
		...mapGetters(['edit_dial_back']),
		dial_back_message: {
			get() {
				return this.$store.getters.dial_back_message
			},
			set(val) {
				this.updateDialBackMessage(val)
			},
		},
	},
	methods: {
		...mapActions(['updateDialBackMessage']),
		playTTS() {
			this.$emit('playTTS', this.dial_back_message)
		},
	},
}
</script>

<style lang="scss" scoped>
.preview-btn {
	margin-top: 1rem;
}
</style>
