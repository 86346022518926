<template>
	<div class="settings-wrapper">
		<div class="outbound-inbound-routing-items q-pt-md">
			<div class="primary-country-code-wrapper">
				<div>
					Primary country code for user phone numbers
				</div>
				<div
					class="country-code"
					:class="$q.screen.lt.sm ? 'small-view' : ''"
				>
					<vue-country-code
						:defaultCountry="country.country_code"
						:disabledFetchingCountry="true"
						:enabledCountryCode="true"
						@onSelect="onSelect"
					>
					</vue-country-code>
				</div>

			</div>
			<details-button
				:tooltip_content="primary_country_code_tooltip_content"
				:name="'primary-country-code'"
			/>

		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'

export default {
	name: 'PrimaryCountryCode',
	components: {
		DetailsButton,
	},
	data() {
		return {
			primary_country_code_tooltip_content: `Select the country code that is relevant for the majority of users in this Dataset - you can overwrite this on
			a per user basis if needed.`,
			country: null,
		}
	},
	computed: {
		...mapGetters(['countries', 'customer_group']),
	},
	methods: {
		...mapActions(['updatePrimaryCountryCode']),
		onSelect({ name, iso2, dialCode }) {
			let primary_country_code = {
				name: name,
				iso2: iso2,
				dialCode: dialCode,
			}
			this.updatePrimaryCountryCode(primary_country_code)
		},
	},
	created() {
		this.country = {
			country: 'United Kingdom',
			country_code: 'GB',
			dial_code: '44',
		}
		let customer_group_settings = JSON.parse(this.customer_group.customer_group.settings);

		if (customer_group_settings.country_code) {
			this.country = customer_group_settings.country_code
		}
	}
}
</script>

<style lang="scss">
.small-view .vue-country-select .dropdown-list{
	width: auto;
}
</style>
<style lang="scss" scoped>
.primary-country-code-wrapper {
	display: flex;
	justify-content: space-between;
	cursor: default;
}

@media (max-width: $breakpoint-sm-max) {
	.primary-country-code-wrapper {
		flex-direction: column;
	}
}

.country-code {
	width: 50%;
}
</style>
