<template>
	<div class="settings-wrapper">
		<div class="outbound-inbound-routing-items q-pt-md">
			<q-select
				v-model="default_tts_voice"
				use-input
				:options="options"
				label="Text to Speech (TTS)"
				name="tts"
				hint="Default TTS voice"
				@filter=filterFn
			/>
			<div
				v-if="default_tts_voice"
				class="preview-btn"
			>
				<div class="font-medium">Example: {{ default_tts_voice_text }}</div>
				<preview-button
					:is_disabled="default_tts_voice"
					:loading="loading"
					@playTTS=playTTS
				/>
			</div>
			<details-button
				:tooltip_content="primary_tts_voice_tooltip_content"
				name="primary-tts"
			/>
		</div>

	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'
import PreviewButton from '@/components/datasets/utils/PreviewButton.vue'

export default {
	name: 'PrimaryTTSVoice',
	components: {
		DetailsButton,
		PreviewButton,
	},
	props: {
		loading: Boolean
	},
	computed: {
		...mapGetters(['people']),
		people_array() {
			return this.$store.getters.people.map((obj) => {
				return {
					value: obj.value,
					label: obj.value,
				}
			})
		},
		default_tts_voice: {
			get() {
				return this.$store.getters.default_tts_voice.value
			},
			set(val) {
				let value = this.$store.getters.people.find((obj) => {
					if(val) {
						return obj.value === val.value
					}
				})
				if (!value) {
					value=[]
				}
				this.updateDefaultTTSVoice(value)
			},
		},
		default_tts_voice_text() {
			let selected_voice_language =
				this.$store.getters.default_tts_voice.language
					.toLowerCase()
					.replace(' ', '_')
			return this.$store.getters.default_tts_voice_text_dictionary[
				selected_voice_language
			]
		},
	},
	data() {
		return {
			primary_tts_voice_tooltip_content: `The voice selected here will be the default TTS used for outbound voice calls and TTS content created for
			answering services custom audio, dial back audio created in this tab and/or content created on your Audio Noticeboard (if applicable).
			You can overwrite this default on a per message basis if needed.`,
			is_loading: false,
			options: []
		}
	},
	methods: {
		...mapActions(['updateDefaultTTSVoice', 'updatePeopleList']),
		playTTS() {
			this.$emit('playTTS', this.default_tts_voice_text)
		},
		filterFn (val, update) {
			if (val === '') {
				update(() => {
					this.options = this.people_array
				})
				return
			}

			update(() => {
				const needle = val.toLowerCase()
				this.options = this.people_array.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
			})
		}
	},
	created() {
		this.updatePeopleList()
	},
}
</script>

<style lang="scss" scoped>
.preview-btn {
	margin-top: 1rem;
}
</style>

